<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />


            <div class="heading-lead">  
                <h3><b>Profile</b></h3> 
            </div>

            <div class="content-body">

                <!-- <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>My Profile</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/profile">Profile</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Edit Profile</li>
                            </ol>
                        </nav>                   
                    </div>
                </div> -->

                <!-- change Profile -->
                <ProfileComponent />


                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import ProfileComponent from '@/components/ProfileComponent.vue';

import axios from 'axios';


export default {
    name: "Profile",
    components: {
        SideNav, TopNav, Copyright, ProfileComponent
    },
    
    data() {
        return{
            page_active:"profile",
            isDisabled: false,
            isDisabled1: false,

            processBtn: 'Update Password',
            processBtn1: 'Update Pin',

            msg_status: '',
            msg_content: '',

            msg_status1: '',
            msg_content1: '',

            vendor_no:'',
            
            pin_msg_content: '',
            reset:{
                oldpassword:'',
                security_question1:'',
                security_question2:'',
                security_answer1:'',
                security_answer2:'',
            },         
            pinreset:{},   
            pass_policy:"",
            pass_stat:"",
            security_questions:[
                {
                    "ID":1,
                    "question":'What is the name of your favorite pet?'
                },
                {
                    "ID":2,
                    "question":'In what city were you born?'
                },
                {
                    "ID":3,
                    "question":'What is the name of your first school?'
                },
                {
                    "ID":4,
                    "question":'What is the name of your favorite Actor?'
                },
                {
                    "ID":5,
                    "question":"What is your mother's maiden name?",
                },
                {
                    "ID":6,
                    "question":'What is the name of your favorite Cousin?'
                },
                {
                    "ID":7,
                    "question":'What was the make of your first car?'
                },
                {
                    "ID":8,
                    "question":'What is your favorite color?'
                },
                {
                    "ID":9,
                    "question":'What City was your high school located?'
                }
            ],
            security_data:{
                security_question1:0,
                security_question2:0
            },            
            addresses:[],
            page:1,
            otp_screen:0,
            password_screen:0,
            freightedid:0,
            subacct_name:'',
            subacct_id:'',
            subacct_email:'',
            subacct_telephone:'',
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Edit Profile";
            },
        },
    },

    mounted () {  
        this.inactivityTime();       
        // this.vendor_no  = this.$store.getters.vendor_no;
        // let subprofiles =  this.$store.getters.subaccounts;
        // let sub_acct  = this.$store.getters.sub_acct;
        // let subprofile_info = subprofiles.filter( el => el.customeraccount == sub_acct );
        // this.addresses = subprofile_info[0].addresses;
        // this.subacct_telephone = subprofile_info[0].telephone;
        // this.subacct_id = subprofile_info[0].customeraccount;
        // this.subacct_name = subprofile_info[0].customername;
        // this.subacct_email = subprofile_info[0].emailaddress;

        // this.fetchAccountQuestions();   
        
        this.fetchAccountBalance();
    },

    methods: {
        
        // # fn() to return AX balance for selected account;
        // # purpose for credit limit check;
        fetchAccountBalance(){
            let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
            axios({
                method: "get",
                url: this.$hostname+"/getsubaccountID",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    // # prepare server response
                    this.$store.commit("set_subaccounts", res.data.data);
                    let sub_acct   = this.$store.getters.sub_acct;
                    let currentacc = res.data.data.filter(el => el.customeraccount == sub_acct);

                    let account_balance     = currentacc[0].creditremaining;

                    // store current in lDB
                    this.$store.commit("set_sub_acct_bal", account_balance); 
                }
            })
        },


        async handlePassword () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.reset.password1 == this.reset.password2 && this.pass_stat == 'ok'){
                    this.isDisabled = true,
                    this.processBtn = 'Processing...';

                    this.reset.vendor_id = this.vendor_no;

                    let data = this.loadNew(this.reset);
                    await axios({
                        method: "post",
                        url: this.$hostname + "/update-password",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = this.response.message;
                                            
                            this.isDisabled     = false;
                            this.processBtn     = 'Update Password';

                            // alert("Your password update was successful, you will redirected to login page.");

                            this.$swal.fire(
                                'Success Alert',
                                "Your password update was successful, you will redirected to login page.",
                                'success'
                            )
                            


                            this.$router.push('/');

                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = this.response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'Update Password';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    this.is401(error.response.status);
                    })  

                    .finally(e => {                                       
                        this.isDisabled = false;
                        this.processBtn = 'Update Password';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "New Password & Confirm Password did not match or meet requirement.";
                } 
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        async checkOldPassword () {
            try {
                this.msg_status     = '';
                this.msg_content    = '';

                if(this.reset.password1 == this.reset.password2 && this.pass_stat == 'ok'){
                    var loading_html =
                        '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://www.c-sgroup.com/images/loading-icon-red.gif" /></div>';
                        this.$swal.fire({
                        title: "",
                        html: loading_html,
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                    

                    this.reset.vendor_id = this.vendor_no;

                    let data = this.loadNew(this.reset);
                    await axios({
                        method: "post",
                        url: this.$hostname + "/check-old-password",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){
                            this.password_screen=1;

                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            )

                        }
                        else{
                            this.$swal.fire(
                                'Alert',
                                res.data.message,
                                'error'
                            )
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    this.is401(error.response.status);
                    })
                }
                else{
                    this.msg_status     = 'alert alert-danger mt-4';
                    this.msg_content    = "New Password & Confirm Password did not match or meet requirement.";
                } 
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        async handlePin () {
            try {
                this.msg_status1     = '';
                this.msg_content1    = '';

                if(this.pinreset.pin1 == this.pinreset.pin2 && this.pinreset.pin1 != undefined){
                    this.isDisabled1 = true,
                    this.processBtn1 = 'Processing...';

                    this.pinreset.vendor_id = this.vendor_no;

                    let data = this.loadNew(this.pinreset);
                    await axios({
                        method: "post",
                        url: this.$hostname + "/update-pin",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                    })

                    .then(res => {
                        this.response = res.data
                        if(this.response.status == true){

                            this.msg_status1     = 'alert alert-success mt-4';
                            this.msg_content1    = this.response.message;
                                            
                            this.isDisabled1     = false;
                            this.processBtn1     = 'Update Pin';

                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            )
                            this.pinreset = {};

                            // this.$router.push('/');

                        }
                        else{
                            this.msg_status1     = 'alert alert-danger mt-4';
                            this.msg_content1    = this.response.message;                       
                            this.isDisabled1     = false;
                            this.processBtn1     = 'Update Pin';
                        }                        
                    })

                    .catch(error => {
                        console.log(error)
                    this.is401(error.response.status);
                    })  

                    .finally(e => {                                       
                        this.isDisabled1 = false;
                        this.processBtn1 = 'Update Pin';
                        console.log(e);
                    })
                }
                else{
                    this.msg_status1     = 'alert alert-danger mt-4';
                    this.msg_content1    = "New Pin & Confirm Pin did not match.";
                } 
            }
            catch (err) {
                this.msg_status1     = 'alert alert-danger mt-4';
                this.msg_content1    = err;
            }            
        },
        
        CheckPassword(x) {
            if(x.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/)){ 
                this.pass_policy    = "Password strength: Strong";
                this.pass_stat      = "ok"
            }
            else {             
                this.pass_policy    = "Minimum of 8 digits with 1 numeric and 1 special character.";
                this.pass_stat      = ""
            }
        },

        requestOTP () {
            try {
                
                this.msg_status1     = '';
                this.msg_content1    = '';

                if(this.pinreset.pin1 == this.pinreset.pin2 && this.pinreset.pin1 != undefined && this.pinreset.oldpin != undefined){

                    var loading_html =
                        '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://www.c-sgroup.com/images/loading-icon-red.gif" /></div>';
                        this.$swal.fire({
                        title: "",
                        html: loading_html,
                        showConfirmButton: false,
                        showCancelButton: false
                    });
                    
                    
                    this.pinreset.vendor_id = this.vendor_no;

                    
                    let data = this.loadNew(this.pinreset);
                    axios({
                        method: "post",
                        url: this.$hostname + "/request-pin-otp",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                    })

                    .then(res => {
                        if(res.data.status == true){
                            this.otp_screen=1;
                            this.response = res.data  
                            

                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            )
                        }
                        else{
                            this.$swal.fire(
                                'Alert',
                                res.data.message,
                                'error'
                            )
                        }

                    })

                    .catch(error => {
                        console.log(error);
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!'
                        })
                    this.is401(error.response.status);
                    })  

                    .finally(e => {
                        console.log(e);
                    })
                }                
                else{
                    this.msg_status1     = 'alert alert-danger mt-4';
                    this.msg_content1    = "New Pin & Confirm Pin did not match.";
                }
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            }            
        },

        fetchAccountQuestions (){
           try {
                 
                    let data = this.loadNew({"vendor_no":this.vendor_no,"subaccountID":this.$store.getters.sub_acct});
                axios({
                    method: "post",
                    url: this.$hostname + "/fetch-account-questions",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                })

                .then(res => {
                    this.security_data = res.data[0];
                    this.reset.security_question1 = this.security_data.security_question1;
                    this.reset.security_question2 = this.security_data.security_question2;

                    // alert(this.response.message);                       
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })  

                .finally(e => {
                    console.log(e);
                })
            
            }
            catch (err) {
                this.msg_status     = 'alert alert-danger mt-4';
                this.msg_content    = err;
            } 
        }
    },

    created () {
    },
};
</script>

<style scoped>
.login-form input.form-control, .select {
    margin: 0 0 20px 0px;
}

</style>

