<template>
    <div class="dashboard">
        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">
            <TopNav />

            <div class="heading-lead h3">
                <b>Delivery</b>
            </div>

            <div class="content-body">
                <div class="row filter-wrap mb-3">

                    <!-- page tabs -->
                    <div class="col-lg-12">
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <a class="nav-link active" aria-current="page" href="#">Delivery Orders</a>
                            </li>
                        </ul>
                    </div>


                    <!-- pending orders -->
                    <div class="col-lg-12">
                        <div class="p-4" style="border: 1px solid #dee2e6;border-top: none">
                            <table class="table table-striped table-hover">
                                <thead>
                                    <tr style="border-bottom: 2px solid #000;">
                                        <th>SN</th>
                                        <th style="width:150px">Sales Order No</th>
                                        <th>AMOUNT</th>
                                        <th>Site</th>
                                        <th>Delivery Address</th>
                                        <th>Date</th>
                                        <th>Time</th>
                                        <!-- <th>Status</th> -->
                                        <th scope="col" style="text-align: center;">Delivery Status</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr v-for="(x,i) in list" :key="i">
                                        <td></td>
                                        <td>{{x.sales_order_number}}</td>
                                        <td>&#x20A6; {{formatPrice(x.amount)}}</td>
                                        <td>{{x.loading_location}}</td>
                                        <td>{{x.ship_address}}</td>
                                        <td>{{formatDate(x.start_time, 'date')}}</td>
                                        <td>{{formatDate( x.start_time, 'time')}}</td>
                                        <!-- <td>{{x.isCompleted}}</td> -->
                                        <td style="text-align: center;">
                                            <!-- data-bs-toggle="modal" data-bs-target="#filterModal4" -->
                                            <a v-if="x.isCompleted != 1" href="#!" @click="confirmPOD(x.sales_order_number);" class="d-block btn-sm font-12 btn btn-success" title='Click to approve proof of delivery'  style="font-size: 10px; padding: 3px 0px;">Confirm Delivery</a> 
                                            <span v-else>Delivered</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="modal fade" id="filterModal4" tabindex="-1" aria-labelledby="exampleModalLabe4" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content shadow-sm">
                                    <div class="modal-header">
                                        <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Enter Proof Of Delivery</h5>
                                        
                                        <button type="button" class="btn-close" id="closebtn" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form  class="login-form" autocomplete="off" method="post"  v-on:submit.prevent="handlePin">
                                            <div class="col-lg-12 text-center mt-4 mb-3">
                                                <h4>PROOF OF DELIVERY</h4>
                                                <p class="font-12">Kindly enter the POD code sent to your mobile phone to confirm delivery.</p>
                                                <hr style="width:15%; margin: 0 auto;">
                                            </div>

                                            <div class="col-lg-6 offset-lg-3 mt-5">                             
                                                <div class="form-group">
                                                    <span>Enter POD code</span>
                                                    <input type="text" autocomplete="off" class="form-control" placeholder="Enter POD code" v-model="pod.otp" required>
                                                </div>
                                            </div>

                                            <div class="col-lg-6 offset-lg-3 mt-3">
                                                <div :class="msg_status1" v-html="msg_content1"></div>
                                                <button type="button" class="btn form-control login-btn mt-0" @click="confirmPOD();" data-bs-dismiss="modal">Proceed</button>
                                            </div>
                                        </form>                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Copyright />                    

            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import moment from 'moment';


import axios from 'axios';

export default {
    name: "Orders",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"orders",         
            
         
            obj_order:{},

            subaccountid:'',
            
            view_index3:0,
            list:[],
            pod:{},
            sub_acct:''
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {  
        this.inactivityTime(); 

        if (this.$store.getters.vendor_no) {
            this.phone      = this.$store.getters.phone; 
            this.vendor_no  = this.$store.getters.vendor_no;    
            this.sub_acct   = this.$store.getters.sub_acct;            
        }
        
        this.obj_order.startdate = moment().subtract(2, 'months').format('YYYY/MM/DD');
        this.obj_order.enddate = moment().add(1, 'days').format('YYYY/MM/DD');

        this.getDeliveryOrders(); // load sales order history from 

        this.fetchAccountBalance();
    },

    methods: {
        // # fn() to return AX balance for selected account;
        // # purpose for credit limit check;
        fetchAccountBalance(){
            let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
            axios({
                method: "get",
                url: this.$hostname+"/getsubaccountID",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    // # prepare server response
                    this.$store.commit("set_subaccounts", res.data.data);
                    let sub_acct   = this.$store.getters.sub_acct;
                    let currentacc = res.data.data.filter(el => el.customeraccount == sub_acct);

                    let account_balance     = currentacc[0].creditremaining;

                    // store current in lDB
                    this.$store.commit("set_sub_acct_bal", account_balance); 
                }
            })
        },


        async getDeliveryOrders () {
            try {              
                let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});                      
                await axios({
                    method: "post",
                    url: this.$hostname+"/getdeliveryorders",
                    data: {'token':data},
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                        this.list = res.data.data;

                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        confirmPOD(orderID){
            try {
                // if(this.pod.otp != undefined){
                if(confirm("Please confirm that your goods have been delivered")){
                    this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false, allowOutsideClick: false});                    
                    this.pod.subaccountID = this.sub_acct;                   
                    this.pod.saleorderno = orderID;

                    let data = this.loadNew(this.pod);
                    axios({
                        method: "post",
                        url: this.$hostname + "/approvepod",
                        data: {'token':data},
                        headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                    }).then(res => {
                        this.response = res.data
                        if(this.response.status == true){
                            this.getDeliveryOrders();
                            this.$swal.fire(
                                'Success Alert',
                                this.response.message,
                                'success'
                            );
                        }
                        else{
                            this.$swal.fire(
                                'Error Alert',
                                this.response.message,
                                'error'
                            );
                        }                        
                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal.fire(
                            'Error Alert',
                            'Error occured',
                            'error'
                        );
                        this.is401(error.response.status);
                    }).finally(e => {                    
                        console.log(e);
                    })
                }
                // else{
                //     this.$swal.fire(
                //         'Error Alert',
                //         'Please enter your POD code.',
                //         'error'
                //     );
                // }
            }
            catch (err) {
                this.$swal.fire(
                    'Error Alert',
                    'Error occured',
                    'error'
                );
            }     
        },
    },

    created () {
    },
    
    computed: {

    },
};
</script>

<style scoped>
.card-text{color: #5a5555;}
.card-title{font-size: 35px;}
.card-body.bg-border {border: 1px solid #006e40b3; box-shadow: none;}
th.active.up > i.fa-caret-up{color:red}
th.active.down > i.fa-caret-down{color:red}
th i {
    font-size: 14px;
}
th i.fa-caret-down{right: 9px !important;top: 4px;}
.filter-area {
    padding: 10px;
    background: #ffffff;
    border: 3px solid #006e40;
    margin-bottom: 10px;
    position: fixed;
    z-index: 100;
    right: 40px;
    width: 57px;
    bottom: 40px;
    box-shadow: 0px 1px 20px 1px #0000007d;
}
.modal-dialog { max-width: 20%; margin: 0 0 0 80%;}
.modal {z-index: 9999;width: 100%;height: 100%;}
.modal-content {height: 100vh;border-radius: 0;border: none;border-left:1px solid #dee2e6 !important;}
.modal-title {font-size: 19px;}
.modal-header { border-bottom: 1px solid #dee2e638;}
.border-b{border:2px solid #c1c1c1}

.nav-link{
    font-weight: 600;
    color: #000 !important;
    /* padding: 10px 30px; */
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
    background: #f7f7f7;
}
.nav-link.active,.nav-link.active:hover {
    color: #495057;
    border-color: #dee2e6 #dee2e6 #fff !important;
    border-radius: 0;
}
.filter-wrap tbody, td, tfoot, th, thead, tr {
    text-transform: uppercase;
}
.table thead th {
    font-weight: 600;
    font-size: .785rem;
    text-transform: uppercase;
    /* letter-spacing: .0625rem; */
}
.nav-link:hover, .nav-link:focus {    
    border-color: #f7f7f7 #f7f7f7 #dee2e6 #f7f7f7 !important;
}






/* .nav-link.active{ 
    border-bottom: 4px solid #006e40 !important;
    border: none;
    top: 2px;
    border-radius: 3px;
} */

.nav-item{margin-right: 10px;}
/* .nav-tabs {border-bottom: 1px solid #dee2e638;} */


#filterModal2 .modal-dialog, #filterModal3 .modal-dialog, #filterModal4 .modal-dialog {
    max-width: 69.2% !important;
    margin: 0 auto !important;
}




table {
    counter-reset: rowNumber;
}

table tbody tr {
    counter-increment: rowNumber;
}

table tbody tr td:first-child::before {
    content: counter(rowNumber);
    margin-right: 0.5em;
}

.modal-body{
    overflow-y: scroll;
}


.login-form input.form-control, .select {
    margin: 0 0 20px 0px;
    border: 1px solid #c8c9ca;
    height: 40px;
    font-size: 12px;
    border-radius: 2px;
}
.login-form span {
    font-size: 12px;
    font-weight: 500;
}
</style>