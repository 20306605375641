<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6 col-4">
                        <h6><b>Cart</b></h6>
                    </div>
                    <div class="col-lg-6 col-8">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/products">Products</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Your Cart</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                
                <div v-if="product_list.length > 0">
                    <div class="row mt-5">
                        <div class="col">
                            <div class="table-responsive bg-white">
                                <table class="table table-borderless mt-3">
                                    <thead>
                                        <tr>
                                            <th scope="col"  class="text-center">ITEM</th>
                                            <th class="td-br"></th>
                                            <th class="td-br">UNIT PRICE</th>
                                            <th class="td-br">TONNES</th>
                                            <th class="text-center td-br">QUANTITY (BAGS/CARTONS)</th>
                                            <th class="td-br">SUB TOTAL</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="x in product_list" :key="x.itemID">
                                            <td class="text-center">
                                                <img class="mt-1" :src="x.image" style="width: 30px;" v-if="x.image" />   
                                                <img class="mt-1" src="https://www.freeiconspng.com/uploads/no-image-icon-6.png" style="width: 30px;" v-else />
                                            </td>

                                            <td class="td-br">
                                                <div style="width: 150px;" class="">{{x.productname}} </div>                                        
                                            </td>

                                            <td class="td-br"> 
                                                <div style="width: 180px;">
                                                    <span v-if="x.discountamount > 0">&#x20A6;{{numTH(x.price - x.discountamount)}}&nbsp; </span>

                                                    <span :class="x.discountamount > 0 ? ' discount-strike text-secondary font-weight-light': ''">&#x20A6;{{numTH(x.price)}}</span>

                                                    <span class="font-12">&nbsp; (per {{unitFormatter(x.unit)}})</span>
                                                </div> 
                                            </td>

                                            <td class="td-br"> 
                                                <div style="width: 70px;">{{Math.ceil(unitCount(x.itemID) / x.bagcount)}} <span class="font-12">tonnes</span></div> 
                                                <!-- <b>{{unitCount(x.itemID) * x.bagcount}}</b> <span class="font-12">{{unitFormatter(x.unit)}}s</span> -->
                                            </td>

                                            <td class="text-center td-br">
                                                <div style="width: 250px;">
                                                    <button class="btn-incremental" @click="decrement(x.itemID,x.bagcount)"><i class="fa fa-minus"></i></button>
                                                    <input :value="x.items = unitCount(x.itemID)" v-on:keyup="setUnit(x.itemID,x.bagcount)" type="tel" 
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="input-control input_counter" :id="'item_'+x.itemID">
                                                    <button class="btn-incremental plus" @click="increment(x.itemID,x.bagcount)"><i class="fa fa-plus"></i></button>
                                                </div>
                                            </td>

                                            <!-- <td  class="text-center td-br"><b>&#x20A6;{{numTH(x.price * x.items * x.bagcount)}}</b></td> -->
                                            <td  class="td-br">
                                                <div style="width:250px">
                                                    <b v-if="x.discountamount > 0">&#x20A6;{{numTH((x.price - x.discountamount) * unitCount(x.itemID))}}</b>
                                                    <b :class="x.discountamount > 0 ? ' discount-strike text-secondary font-weight-light': ''">&nbsp; &#x20A6;{{numTH(x.price * unitCount(x.itemID))}}</b>
                                                </div>                                                
                                            </td>

                                            <td class="text-center">
                                                <button class="btn del-btn" @click="removeFromCart(x.itemID)"><i class="fa fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-5">
                        <div class="col-lg-3 offset-lg-9">
                            <!-- <div  class="subtotal_area"><b>Total Price = &#x20A6;{{numTH(sumTotal)}}</b> </div> -->                            
                            <div class="mt-2 text-center"><a href="/checkout" class="btn checkout-btn rounded form-control shadow-sm">PROCEED TO CHECKOUT &#x20A6;{{numTH(sumTotal)}}</a></div>
                            <div class="mt-2 text-center"><a href="/products" class="btn btn-success form-control"><i class="fa fa-arrow-left"></i>&nbsp;&nbsp;&nbsp; CONTINUE SHOPPING</a></div>
                        </div>
                    </div>


                    <div class="card cart-progress d-none">
                        <div class="card-body cart-progress-content">

                            <p class="cart-p-content-svg">
                                <svg width="32" height="21" viewBox="0 0 42 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M33.8861 20.0056C32.816 20.0056 31.9485 19.1236 31.9485 18.0355C31.9485 16.9475 32.816 16.0654 33.8861 16.0654C34.9562 16.0654 35.8237 16.9475 35.8237 18.0355C35.8237 19.1236 34.9562 20.0056 33.8861 20.0056Z" fill="white" stroke="black" stroke-width="0.5" stroke-miterlimit="10"/>
                                    <path d="M37.4917 9.99017H34.2088V6.10449" stroke="black" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.35147 20.0056C6.28137 20.0056 5.41388 19.1236 5.41388 18.0355C5.41388 16.9475 6.28137 16.0654 7.35147 16.0654C8.42157 16.0654 9.28906 16.9475 9.28906 18.0355C9.28906 19.1236 8.42157 20.0056 7.35147 20.0056Z" stroke="black"  fill="#fff" stroke-width="0.5" stroke-miterlimit="10"/>
                                    <path d="M13.1637 20.0056C12.0936 20.0056 11.2261 19.1236 11.2261 18.0355C11.2261 16.9475 12.0936 16.0654 13.1637 16.0654C14.2338 16.0654 15.1013 16.9475 15.1013 18.0355C15.1013 19.1236 14.2338 20.0056 13.1637 20.0056Z" fill="#fff" stroke="black" stroke-width="0.5" stroke-miterlimit="10"/>
                                    <path d="M37.7565 17.1357H40.65C40.8202 17.1357 40.9835 17.0674 41.1044 16.9456C41.2254 16.8239 41.2942 16.6586 41.2959 16.4855L41.3514 12.3634C41.3552 12.0515 41.2859 11.7431 41.1493 11.4638C40.9787 11.1157 40.7617 10.6751 40.7359 10.6403C40.6984 10.5897 40.1553 9.03989 39.2291 5.64345C38.2926 2.20891 32.4282 1.75579 30.4389 1.70326C30.353 1.70151 30.2676 1.71721 30.1878 1.74942C30.1079 1.78164 30.0352 1.82974 29.9738 1.89089C29.9125 1.95205 29.8638 2.02503 29.8305 2.10558C29.7972 2.18612 29.7801 2.2726 29.7801 2.35995V16.479C29.7801 16.6891 29.7821 16.9682 29.7821 16.9682" stroke="black" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" fill="#fff" stroke-linejoin="round"/>
                                    <path d="M16.9053 16.682H27.52V1.6567C27.52 1.29551 27.2294 1 26.8742 1H1.64673C1.29151 1 1.00087 1.29551 1.00087 1.6567V16.0253C1.00087 16.3864 1.29151 16.682 1.64673 16.682H3.65537" stroke="black" fill="#006e40" stroke-width="0.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </p>


                            <p class="mt-1">
                                <small>ADDED TO CART...<br>
                                <b>30% FULL</b></small>
                            </p>
                        </div>  

                        <div style="box-shadow: rgb(242 242 242) 0px 1px 16px 5px; top:-8px">
                            <div style="border-top:6px solid #006e40; border-radius:0 0 0px 5px; width:30%;"></div> 
                        </div>         
                    </div>
                </div>

                
                <div v-if="no_cart" class="text-center p-5">
                    <img src="@/assets/images/empty-cart.png" />
                    <p class="mt-5">It seems your cart is empty, please <a href="/products">click here</a> to add an item</p>
                </div>


                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';



export default {
    name: "Cart",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active: "orders",
            obj_product:{},
            product_list:[],
            all_product_list:[],
            sub_acct:'',
            location:'',
            no_cart:false,
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Your Cart";
            },
        },
    },

    computed: {
        cartitem() {
            return this.$store.getters.cart;
        },        

        cartarray (){
            return this.$store.getters.cartdata;
        },

        sumTotal(){
            return this.product_list.reduce( (acc, product) =>  acc += product.items * (product.price - product.discountamount), 0);
        }
    },
    
    mounted () {
        this.inactivityTime(); 
        this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

        this.sub_acct = this.$store.getters.sub_acct;
        this.location = this.$store.getters.collectionarea;  
        
        this.loadProduct();
        this.fetchAccountBalance();
    },

    methods: {
        // # fn() to return AX balance for selected account;
        // # purpose for credit limit check;
        fetchAccountBalance(){
            let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
            axios({
                method: "get",
                url: this.$hostname+"/getsubaccountID",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    // # prepare server response
                    this.$store.commit("set_subaccounts", res.data.data);
                    let sub_acct   = this.$store.getters.sub_acct;
                    let currentacc = res.data.data.filter(el => el.customeraccount == sub_acct);

                    let account_balance     = currentacc[0].creditremaining;

                    // store current in lDB
                    this.$store.commit("set_sub_acct_bal", account_balance); 
                }
            })
        },


        async loadProduct () {
            try {
                this.obj_product.location     = this.location;
                this.obj_product.subaccountID = this.sub_acct;
           
                let data = this.loadNew(this.obj_product);
                
                await axios({
                    method: "get",
                    url: this.$hostname+"/productcatalogueselfcollection",
                    params: {'token':data},
                    headers: { "Content-Type": "multipart/form-data", "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                })

                .then(res => {
                    this.all_product_list = res.data.data;
                    let product_list = this.all_product_list.filter( el => this.cartitem.includes(el.itemID));
                    
                    let key = 'itemID';

                    let arrayUniqueByKey = [...new Map(product_list.map(item =>
                    [item[key], item])).values()];

                    this.product_list = arrayUniqueByKey;

                    if(this.product_list.length == 0){
                        this.no_cart = true;
                    }
                    this.$swal.close();
                })

                .catch(error => {
                    console.log(error)   
                    this.is401(error.response.status);
                })  

                .finally(e => {                 
                    console.log(e);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        removeFromCart(x){
            let data = this.cartitem;
            let index = data.indexOf(x);
            
            if (index > -1) {data.splice(index, 1);}

            let cartarr = this.cartarray.filter( el => el.itemID !== x );

            this.product_list = this.product_list.filter( el => el.itemID !== x ); // remove from AX data;


            this.$store.commit("updateCart", data);
            this.$store.commit("updateCartdata", cartarr);
        },

        increment(x,y){
            let input  = Number(document.getElementById('item_'+x).value) + 1;
            document.getElementById('item_'+x).value = input;
            this.updateCartQuantity (input, x, '+', y);
        },

        setUnit(x,y){
            let input = Number(document.getElementById('item_'+x).value);
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '', y);
        },

        decrement(x,y){
            let input = Number(document.getElementById('item_'+x).value) - 1;
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '-', y);
        },

        updateCartQuantity (value, product_id, action, pack) {
            let cart_array = this.$store.getters.cartdata;
            cart_array.forEach(i => {
                if (product_id == i.itemID) {
                    if (action == '+') {
                        // i.unit = parseInt(i.unit) + 1;
                        
                        i.qty = parseInt(i.qty) + 1;
                        i.unit = (parseInt(i.qty) + 1)/pack;
                    }
                    else if (action == '-') {
                        // i.unit = parseInt(i.unit) - 1;
                        i.qty = parseInt(i.qty) - 1;
                        i.unit = (parseInt(i.qty) - 1)/pack;
                    }
                    else {
                        // i.unit = value;
                        i.qty = value;
                        i.unit = value/pack;
                    }
                }
            })  

            this.$store.commit("updateCartdata", cart_array);
            this.product_list = this.product_list.map((product) => {
                if (product.itemID == product_id){
                    product.items = this.unitCount(product_id)
                }
                return product;
            })
        },

        unitCount(x){
            if(this.cartarray.length > 0)
                return this.cartarray.filter( el => el.itemID == x )[0].qty;
        },

        numTH(x){
            return this.thDFormat(Number(x));
        },

        unitFormatter(x){
            // 
            let lastChar = x.substr(x.length - 1)

            if(x == 'gal'){
                return 'Gallon';
            }
            else{
                if(lastChar == 's'){
                    return x.slice(0, -1);
                }
                else{
                    return x;
                }
            }
            
        }
    },
};
</script>

<style scoped>
    .input_counter{width: 100px; margin: 0 5px; text-align:center;outline: none;border: 1px solid #f2f2f2;}
    .subtotal_area{border-bottom: 1px solid #f3f3f369;;border-top: 1px solid #f3f3f369;padding: 10px 0; color:#006e40;}
</style>