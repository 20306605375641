<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    components: {
      
    },
    data () {
      return {        
        webpage: ['Login', 'ForgotPassword', 'ResetPassword', 'Registration', 'OTPValidation', 'ForgotPasswordSuccess']
      }
    },
    mounted () {     
      let current_page = this.$route.name;
      // if(current_page !== 'Login' || current_page !== 'ForgotPassword' || current_page !== 'ResetPassword' || current_page !== 'Registration' || current_page !== 'OTPValidation' || current_page !== 'ForgotPasswordSuccess'){
        
      if(!this.webpage.includes(current_page)){
        if(this.$store.getters.subaccounts.length === 0){                   
              this.$router.push('/');
              return;
        }

        let data = this.loadNew({"subaccountID" : this.$store.getters.subaccounts[0].customeraccount});
        axios({
            method: "get",
            url: this.$hostname+"/getsubaccountID",
            headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
            params:{'token':data},
        })
        .then(res => {
            if(!res.data.status){              
              this.$router.push('/');
            }
        })
        .catch(error => {       
          console.log(error);       
          this.$router.push('/');
        })

        // if(!localStorage.isLoggedIn){
        //       this.$router.push('/');
        // }
      } 

    },
  }
</script>