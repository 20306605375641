<template>
    <div class="container-fluid">
        <div class="row">

            <!-- import -->
            <DashboardNav/>

            <div class="main-section">

                <!-- greetings -->
                <TimeGreetings />


                <div class="row">
                    <div class="col-sm-6 col-lg-7 p-5 sm-base-pad-5">

                        <p><b><a class="text-dark" href="/home"><i class="fa fa-arrow-left"></i> &nbsp;&nbsp;Back</a></b></p>
                        <p><b>Preferred mode of delivery</b></p>

                        <!-- tab nav area -->
                        <div class="tab-nav">                            
                            <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="2" v-model="collection_method" :checked="collection_method == 2">
                            <label class="form-check-label" for="exampleRadios2"> FMN-Delivery </label>

                            <input class="form-check-input fmn-delivery" type="radio" name="exampleRadios" id="exampleRadios1" value="1" v-model="collection_method" :checked="collection_method == 1">
                            <label class="form-check-label" for="exampleRadios1"> Self-Collection </label>
                        </div>


                        <hr class="mt-5 mb-5">


                        <!-- self collection area -->
                        <div v-if="collection_method == 1">
                            <h5><b>Choose a location</b></h5>
                            <form class="location-list" autocomplete="off" method="post" v-on:submit.prevent="handleSelfCollection">
                                
                                <div class="mt-2" v-for="x in location_list" :key="x.ID">
                                    <input class="form-check-input" type="radio" name="exampleRadios" :value="x" v-model="option_val" :id="x.ID" required>
                                    <label class="form-check-label" :for="x.ID">  {{x.locationname}}  </label>
                                </div>

                                <div :class="msg_status" v-html="msg_content"></div>
                                <button type="submit" class="btn col-lg-5 col-8 login-btn">Proceed</button>

                            </form>
                        </div>


                        <!-- freighted area -->
                        <div v-else>
                            
                            <h5><b>Ship from location</b></h5>
                            <!-- <div class="mb-2">
                                <input class="form-control font-12" list="location_pickup" id="location_pickups" type="text" placeholder="Filter pickup location">
                                <i class="fa fa-search" style="    position: absolute; right: 13px; top: 10px;"></i>
                            </div>
                            <div class="mb-5 row font-12" style="margin: 0;">
                                
                                <div class="col-lg-3" v-for="x in location_pickup" :key="x.siteid" style="background:#fff; border: 1px solid #f3f3f3; padding:10px 20px">
                                    <input class="form-check-input" type="radio" name="exampleRadios" :value="x.siteid" v-model="pickup_val" :id="x.siteid" required>
                                    <label class="form-check-label" :for="x.siteid">  {{x.name}}  </label>
                                </div>

                            </div> -->

                            <div class="mb-5 row">
                                <div class="col-lg-6">
                                <v-select v-model="pickup_area" placeholder="Select Ship from location"
                                    :options="location_pickup" 
                                    label="name"
                                ></v-select>
                                </div>
                            </div>


                            <h5 class="mt-5"><b>Choose a delivery address</b></h5>
                            <form class="mode-of-delivery" autocomplete="off" method="post" v-on:submit.prevent="handleFreightedCollection">
                                <div class="row">


                                    <div class="col-lg-6" v-for="(x,i) in addresses" :key="i">
                                        <div class="card mt-3">
                                            <div class="card-body" :class="freightedid == i ? 'active' : ''" @click="freightedid = i" style="min-height:100px">
                                                <!-- <div class="row">
                                                    <div class="col-9">
                                                        <b>{{subacct_name}}</b> - <b>{{subacct_id}}</b>
                                                    </div>
                                                </div> -->

                                                <p class="mt-2 font-12" style="font-weight: 500;">
                                                    <span>{{x.street}}, {{x.city}}, {{x.state}}</span>
                                                </p>

                                                <div class="font-14"  style="font-weight: 500;">{{subacct_telephone}}</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div :class="msg_status2" v-html="msg_content2"></div>
                                <button type="submit" class="btn col-lg-5 col-12 login-btn">Proceed</button>

                            </form>
                        </div>
                    </div>

                    <div class="col-lg-4 offset-lg-1 mt-5  d-none d-sm-none d-lg-block">                
                        <img src="@/assets/images/truck_fmn.png" class="mt-5" style="bottom: 0; position: absolute;">
                    </div>

                </div>

                <div class="row" style="background:#fff">

                    <!-- import -->
                    <Copyright />
                </div>
            </div>

        </div>
    </div>
</template>




<script>
import DashboardNav from '@/components/DashboardNav.vue';
import TimeGreetings from '@/components/TimeGreetings.vue';
import Copyright from '@/components/Copyright.vue';
import axios from 'axios';

export default {
    name: "DeliveryMode",
    components: {
        DashboardNav, TimeGreetings, Copyright
    },
    
    data() {
        return{
            msg_status: '',
            msg_content: '',
            msg_status2: '',
            msg_content2: '',
            collection_method: 2, //delivery_method
            freightedid: 0,
            option_val:{},
            pickup_val:'',
            location_list:[],
            subprofiles:[{"customername":"", "creditremaining":0}],
            sub_acct:"",
            addresses:[],
            subacct_telephone:"",
            subacct_id:"",
            subacct_name:"",
            location_pickup:[],
            pickup_area:'',
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN - Select Mode of Delivery";
            },
        },
    },
    
    mounted () {
        this.inactivityTime(); 
        this.loadLocation();
        this.getLocation();

        let subprofiles =  this.$store.getters.subaccounts;
        this.subprofiles =  subprofiles;

        let sub_acct  = this.$store.getters.sub_acct;
        let subprofile_info = subprofiles.filter( el => el.customeraccount == sub_acct );
        this.addresses = subprofile_info[0].addresses;
        this.subacct_telephone = subprofile_info[0].telephone;
        this.subacct_id = subprofile_info[0].customeraccount;
        this.subacct_name = subprofile_info[0].customername;


        
        this.$store.commit("updateCart", []);
        this.$store.commit("updateCartdata", []);

        this.fetchAccountBalance();
    },

    methods: {       
        // # fn() to return AX balance for selected account;
        // # purpose for credit limit check;
        fetchAccountBalance(){
            let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
            axios({
                method: "get",
                url: this.$hostname+"/getsubaccountID",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    // # prepare server response
                    this.$store.commit("set_subaccounts", res.data.data);
                    let sub_acct   = this.$store.getters.sub_acct;
                    let currentacc = res.data.data.filter(el => el.customeraccount == sub_acct);

                    let account_balance     = currentacc[0].creditremaining;

                    // store current in lDB
                    this.$store.commit("set_sub_acct_bal", account_balance); 
                }
            })
        },
        
        
        // async getLocation () {
        //     try {
        //         await axios({
        //             method: "get",
        //             url: "https://fmnapi.fmnplc.website/fetchfreightsites",
        //             data: {"fn": 'loadLocation'},
        //             headers: { "Content-Type": "multipart/form-data" ,
                            // "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
        //         })

        //         .then(res => {
        //             this.location_pickup = res.data.data;                 
        //         })

        //         .catch(error => {
        //             console.log(error)
        //         })
        //     }
        //     catch (err) {
        //         console.log(err)
        //     }            
        // },

        getLocation(){
            let location = [
                {
                    "siteId": "Agbara",
                    "name": "Agbara",
                    "category": "Lagos",
                    "account_type":"C"
                },
                {
                    "siteId": "Apapa",
                    "name": "Apapa",
                    "category": "Lagos",
                    "account_type":"C,N,B"
                },
                {
                    "siteId": "Calabar",
                    "name": "Calabar",
                    "category": "Calabar",
                    "account_type":"C,N,B"
                },
                {
                    "siteId": "Ibadan",
                    "name": "Ibadan PEOL",
                    "category": "Ibadan",
                    "account_type":"C"
                },
                {
                    "siteId": "Ibadan",
                    "name": "Ibadan Eagle",
                    "category": "Ibadan",
                    "account_type":"B,N"
                },
                {
                    "siteId": "Iganmu",
                    "name": "Iganmu",
                    "category": "Lagos",
                    "account_type":"C"
                },

                // {
                //     "siteId": "Magboro",
                //     "name": "Magboro",
                //     "category": "Lagos"
                // },
                // {
                //     "siteId": "PH",
                //     "name": "PH",
                //     "category": "PH"
                // },
                // {
                //     "siteId": "Kano",
                //     "name": "Kano",
                //     "category": "Kano"
                // },
                // {
                //     "siteId": "CSC Abuja",
                //     "name": "CSC Abuja",
                //     "category": "Abuja"
                // }
            ]

            let subacct_code  = this.$store.getters.sub_acct.split('-')[1];
            let business_rules = subacct_code.charAt(0)
            this.location_pickup = location.filter(r=>r.account_type.match(business_rules));

            
        },

        async loadLocation () {
            try {
                await axios({
                    method: "get",
                    url: this.$hostname+"/loadlocation",
                    headers: { "Content-Type": "multipart/form-data" ,
                               "Authorization" : `Bearer ${this.$store.getters.headerstoken}` }
                })

                .then(res => {
                    let subacct_code  = this.$store.getters.sub_acct.split('-')[1];
                    let business_rules = subacct_code.charAt(0)
                    this.location_list = res.data.filter(r=>r.account_type.match(business_rules));               
                })

                .catch(error => {
                    console.log(error)
                    this.is401(error.response.status);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        handleSelfCollection () {
            if(this.option_val){
                this.$store.commit("set_deliveryMode", 'self');
                this.$store.commit("set_collectionarea", this.option_val.locationID);
                this.$store.commit("set_collectionName", this.option_val.locationname);

                console.log(this.option_val.locationID, this.option_val.locationname)

                this.$router.push('/products');
            }
            else{
                this.msg_status = 'alert alert-danger mt-4';
                this.msg_content= "Kindly select a location.";
            }
        },

        handleFreightedCollection () {
            if(this.freightedid !== ""  && this.pickup_area.siteId !== undefined){                
                this.$store.commit("set_deliveryMode", 'freighted');
                this.$store.commit("set_collectionarea", this.pickup_area.siteId);
                this.$store.commit("set_collectionName", this.pickup_area.name);
                this.$store.commit("set_collectionCategory", this.pickup_area.category);
                this.$store.commit("set_deliveryaddress", this.freightedid);

                this.$router.push('/products');
            }
            else{
                this.msg_status2 = 'alert alert-danger mt-4';
                this.msg_content2= "Kindly select your ship from location.";
            }
        },
    },
};
</script>

<style scoped>
.form-check-label{ margin-left:10px;}
.form-check-input { border: 2px solid #14142B; border-radius: 8px !important;}
.form-check-input:checked {border-radius: 8px !important;}
.card{cursor:default;}
.login-form .form-check-input { border: 2px solid #14142B; border-radius: 8px !important;}
ul {padding-left: 10px; margin-bottom: 0;}
.side-nav-bg-cover {width: 30px;left: 0px;}
.side-nav {width: 80px;}
.side-link.active > a {padding: 0 5px 0 25px;}
.side-nav ul li a {color: #fff;display: block;padding: 0 5px 0px 25px;}
.main-section {width: calc(100% - 80px); margin-left: 80px; background-position: 45% 70%;}
.side-link-after {padding: 15px 0px 20px !important;}
.subprofile .card-body {box-shadow: none;}
.form-check-label {font-weight: 600;font-size: 12px;}
.side-link.active, .container-fluid {background: #f6f6f6 !important;}
.card{ background: #fff;}
text{ display:none !important;}
.fmn-delivery{margin-left: 40px;}
</style>

