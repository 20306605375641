<template>
    <div class="products">
        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">
            <TopNav @searchProduct="searchProduct($event)"/> 

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6 col-6"><h6><b>Product</b></h6></div>

                    <div class="col-lg-6 col-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Product</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>

                <div class="mt-5 mb-4 col-lg-12">
                    <ul class="nav nav-tabs">
                        <li class="nav-item" id="all_category" style="display:none">
                            <a @click="filterProduct('all')" class="nav-link" :class="obj_product.type == 'all' ? 'active' : ''" aria-current="page" href="#">All Products</a>
                        </li>
                        <li class="nav-item" v-for="(x,i) in product_category" :key="i">
                            <a @click="filterProduct(x)" class="nav-link" :class="obj_product.type == x ? 'active' : ''" aria-current="page" href="#" style="text-transform:capitalize">{{x}}</a>
                        </li>
                    </ul>
                </div>

                <div class="row sm-row" v-if="product_list.length > 0">
                    <div class="col-lg-3 mb-4 shadow-hover border product-hover" v-for="(x,i) in product_list" :key="i">
                        <div class="">
                            <div class="" style="padding:10px; min-height: 300px; overflow: hidden;background: #fff; border-radius: 5px;">

                                <span class="alert alert-warning blink" style="font-size:10px;position:absolute;left:20px;display:block;z-index:200;top: 5px;border-radius: 3px;padding:1px 5px" 
                                v-if="(x.applyfreight > 0 || (x.freefreight_region != null ? (x.freefreight_region.toLowerCase().split(',').includes(deliveryRegion)) : false)) && $store.getters.deliveryMode !== 'self'">
                                    <span v-if="(x.freefreight_region != null && (x.freefreight_region.toLowerCase().split(',')).includes(deliveryRegion))">
                                        Free delivery to <span class="text-capitalize">{{ deliveryRegion }}</span>
                                    </span>

                                    <span v-else>Free delivery</span>                              
                                </span>

                                <div class="wrapper">
                                    <span><i class="fa fa-ellipsis-v color-secondary font-12"></i></span>
                                    <div class="tooltip"><small class="font-10" style="font-weight: 300;">1 tonne will load {{x.bagcount}} {{unitFormatter(x.unit)}}s</small></div>
                                </div>

                                <span class="discount_percent" v-if="x.discountamount > 0">-{{Math.ceil((x.discountamount * 100)/x.price)}}%</span>

                                <div class="text-center ">
                                    <img :src="x.image" class="product-img" v-if="x.image" />   
                                    <img src="https://www.freeiconspng.com/uploads/no-image-icon-6.png" class="product-img" v-else />
                                    <!-- <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png" class="product-img" v-else /> -->
                                </div>

                                <p class="p-name-sm bolder">
                                    <span>{{x.productname}}</span>
                                </p>

                                <!-- ({{ thDFormat(Number(x.netweight))}}Kg) -->

                                <p class="color-scope bolder"> 
                                    <span class="color-scope" style="margin-right: 2px;font-size: 14px;" v-if="x.discountamount > 0">&#x20A6;{{thDFormat(Number(x.price - x.discountamount))}}</span>
                                    <span class="color-scope" :class="x.discountamount > 0 ? ' discount-strike text-secondary font-weight-light': ''"> &#x20A6;{{ thDFormat(Number(x.price)) }}</span> 
                                    
                                    <!-- <span class="font-12"> (Per {{unitFormatter(x.unit)}}) </span>  -->
                                    
                                    <!-- <br>  -->

                                    <!-- .slice(0, -1) -->
                                    <!-- <small class="font-10">{{x.bagcount}} {{unitFormatter(x.unit)}}s per Tonne</small> -->
                                </p>

                                <div :id="'showE'+x.itemID" v-if="!cartitem.includes(x.itemID)">
                                    <!-- <small>Enter number of {{unitFormatter(x.unit)}}s</small><br> -->
                                    <input value="" :placeholder="'No of ' + unitFormatter(x.unit) +'s'" type="number" oninput="this.value < 1 ? this.value = 0 : this.value" class="form-control font-14" style="text-align:center;outline: none;border: 1px solid #006e4038;" :id="'item2_'+x.itemID">
                                </div>

                                <span v-if="!cartitem.includes(x.itemID)">
                                    <button class="btn bg-secondary shadow-sm border form-control rounded font-12 bolder add-cart-btn p-2 mt-1" 
                                    @click="addToCart(x.itemID, x.bagcount, x.freefreight_region != null ? (x.freefreight_region.toLowerCase().split(',').includes(deliveryRegion) ? '1' : x.applyfreight) : x.applyfreight)">
                                        ADD TO CART
                                    </button>
                                </span>

                                <span v-else style="/*bottom: 10px; */ /*position: absolute;*/ /* right: 5px;*/  /*left: 5px;*/ text-align: center; padding-top: 13px;  display: block text-align:center">
                                    <button class="rounded btn-sm btn bg-scope text-white shadow" @click="decrement(x.itemID, x.bagcount)">
                                        <i class="fa fa-minus bold"></i>
                                    </button>

                                    <input  :value="unitCount(x.itemID)"  v-on:keyup="setUnit(x.itemID, x.bagcount)" type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" class="input-control" style="width: calc(100% - 75px); margin: 0 5px; text-align:center;outline: none;border: 1px solid #f2f2f2;height: 30px; top: 3px;"  :id="'item_'+x.itemID">
                                    
                                    <button class="rounded btn-sm btn bg-scope text-white shadow" @click="increment(x.itemID, x.bagcount)">
                                        <i class="fa fa-plus bold"></i>
                                    </button>

                                    <br />

                                    <div style="background: #f2f2f2; padding: 7px 10px;" class="font-14 label text-center label-success mt-2 rounded shadow-sm">{{Math.ceil(unitCount(x.itemID) / x.bagcount)}} tonne{{unitCount(x.itemID) / x.bagcount > 1 ? 's' : ''}} </div>
                                    <!-- <span class="font-14">{{thDFormat(unitCount(x.itemID) * x.bagcount) }} {{unitFormatter(x.unit)}}s in {{unitCount(x.itemID)}} tonnes </span> -->
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="pageloader == 0">
                    <div class="p-5 text-center">
                        <img src="@/assets/images/loading.jpg" style="width: 30px; margin-top:120px" />
                    </div>
                </div>

                <div v-else class="row text-center">
                    <div class="p-5">
                        <img src="@/assets/images/product_not_found.jpg" style="width: 250px;" />
                        <p class="mt-5">Products unavailable for this category</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-lg-4 offset-lg-4" v-if="cartitem.length > 0">
                        <div class="mt-4 text-center"><a href="/checkout" class="btn checkout-btn rounded form-control">PROCEED TO CHECKOUT &nbsp;&nbsp;&nbsp;<i class="fa fa-arrow-right"></i></a></div>
                    </div>
                </div>

                <Copyright />
            </div>
        </div>
    </div>
</template>


<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';



export default {
    name: "Products",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"products",
            obj_product:{
                "type":'all'
            },
            product_list:[],

            all_product_list:[],
            product_category:[],
            pageloader:0,
            
            sub_acct:'',
            product_data:{},
            location:'',
            product_search:'',
            delivery_region:''
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Products";
            },
        },
    },
        
    computed: {
        cartitem(){  return this.$store.getters.cart; },        
        cartarray(){ return this.$store.getters.cartdata; },
        deliveryRegion(){
            let subacct = this.$store.getters.sub_acct;
            let allacct = this.$store.getters.subaccounts;
            let account_info = allacct.filter( el => el.customeraccount == subacct ); 
            if(this.$store.getters.deliveryaddress !== ''){
                return (account_info[0].addresses[this.$store.getters.deliveryaddress].state).toLowerCase();
            }
            else{
                return "";
            }
        }
    },
    
    mounted () {
        this.inactivityTime(); 
        
        this.$swal.fire({title: "", html: this.$preloader, showConfirmButton: false, showCancelButton: false}); // preloader

        this.sub_acct       = this.$store.getters.sub_acct;  
        this.location       = this.$store.getters.collectionarea;  
        this.loadProduct();
        this.fetchAccountBalance();

        // this.fetchCart();
    },

    methods: {
        // # fn() to return AX balance for selected account;
        // # purpose for credit limit check;
        fetchAccountBalance(){
            let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
            axios({
                method: "get",
                url: this.$hostname+"/getsubaccountID",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    // # prepare server response
                    this.$store.commit("set_subaccounts", res.data.data);
                    let sub_acct   = this.$store.getters.sub_acct;
                    let currentacc = res.data.data.filter(el => el.customeraccount == sub_acct);

                    let account_balance     = currentacc[0].creditremaining;

                    // store current in lDB
                    this.$store.commit("set_sub_acct_bal", account_balance); 
                }
            })
        },

        async loadProduct () {
            try {
                this.obj_product.location = this.location;
                this.obj_product.subaccountID = this.sub_acct;
                
                let data = this.loadNew(this.obj_product);
                await axios({
                    method: "get",
                    url: this.$hostname+"/productcatalogueselfcollection",
                    params: {'token':data},
                    headers: { "Content-Type": "multipart/form-data",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                })

                .then(res => {
                    
                    this.$swal.close();
                    
                    this.pageloader   = 1;
                    let product_lists = res.data.data;

                    let result = product_lists.reduce(function (r, a) {
                        r[a.category.toLowerCase()] = r[a.category.toLowerCase()] || [];
                        r[a.category.toLowerCase()].push(a);
                        return r;
                    }, Object.create(null));


                    let subacct_code  = this.sub_acct.split('-')[1];
                    let business_rules = subacct_code.charAt(0)


                    if(business_rules == 'B'){
                        this.product_category =  Object.keys(result).filter( el => el == 'flour' );
                        this.product_data = result;           
                        
                        this.all_product_list = product_lists.filter( el => el.category == 'flour' ); 

                        this.filterProduct("flour");
                    }

                    else if(business_rules == 'C'){
                        document.querySelector('#all_category').style.display = "block";
                        this.product_category = Object.keys(result).filter( el => el != 'flour' );
                        this.product_data = result;
                        
                        this.all_product_list = product_lists.filter( el => el.category.toLowerCase() != 'flour' ); 
                        this.filterProduct("all");
                    }
                    
                    else{
                        document.querySelector('#all_category').style.display = "block";
                        this.product_category = Object.keys(result).filter( el => el == 'flour' || el == 'ball foods' );
                        this.product_data = result;
                        
                        this.all_product_list = product_lists.filter( el => el.category.toLowerCase() == 'flour' || el.category.toLowerCase() == 'ball foods'); 
                        this.filterProduct("all");

                        // this.product_data = [];
                        // this.product_category = [];
                        // // alert("No business rules found on account!");
                        
                        // this.$swal.fire(
                        //         'Success Alert',
                        //         "No business rules found on account!",
                        //         'success'
                        //     )
                    }
                })

                .catch(error => {
                    console.log(error)                    
                    this.is401(error.response.status);
                })  

                .finally(e => {                 
                    console.log(e);
                })
            }
            catch (err) {
                console.log(err)
            }            
        },

        filterProduct(x){
            let key = 'itemID';

            if(x == 'all'){
                this.obj_product.type = 'all';
                let product_list = this.all_product_list;
                
                let arrayUniqueByKey = [...new Map(product_list.map(item =>
                [item[key], item])).values()];

                this.product_list = arrayUniqueByKey;
            }
            else{
                // this.$store.commit("set_selected_category", x);
                this.obj_product.type = x;
                let product_list = this.product_data[x];

                let arrayUniqueByKey = [...new Map(product_list.map(item =>
                [item[key], item])).values()];

                this.product_list = arrayUniqueByKey;
            }
        },

        addToCart(x,y,z){

            let applyFreight = Number(z);
            let canAddToCart = true;

            let data = this.cartitem;
            let cartarr = this.cartarray;

            console.log(x,y,applyFreight);

            //logic to consider if product can be added to cart
            if(data.length > 0 && applyFreight === 1 && this.$store.getters.deliveryMode !== 'self'){
                if(cartarr[cartarr.length - 1].applyfreight === 1){
                   canAddToCart = true; 
                }
                else{
                    canAddToCart = false; 
                }
            }
            if(data.length > 0 && applyFreight === 0 && this.$store.getters.deliveryMode !== 'self'){
                if(cartarr[cartarr.length - 1].applyfreight === 1){
                   canAddToCart = false; 
                }
                else{
                    canAddToCart = true;
                }
            }

            if(canAddToCart){
                let preset_unit = document.getElementById('item2_'+x).value;

                document.getElementById('showE'+x).style.display = 'none';

                let qty = preset_unit == '' ? 1 : preset_unit == 0 ? 1 : preset_unit;

                let unit = qty / y;

                let cartdata = {"itemID":x,  "unit":unit, "qty":qty, "applyfreight":applyFreight};

                data.push(x);
                cartarr.push(cartdata);

                this.$store.commit("updateCart", data);
                this.$store.commit("updateCartdata", cartarr);            
                // this.saveCart();
            }
            else{
                this.$swal.fire(
                    'Alert',
                    "Free delivery products cannot be mixed with non free delivery product!",
                    'info'
                )
            }
        },

        removeFromCart(x){
            const index = this.cartitem.indexOf(x);
            let data = this.cartitem;
            if (index > -1) {data.splice(index, 1);}

            
            let cartarr = this.cartarray.filter( el => el.itemID !== x ); 


            this.$store.commit("updateCart", data);
            this.$store.commit("updateCartdata", cartarr);
        },

        increment(x,y){
            let input  = Number(document.getElementById('item_'+x).value) + 1;
            document.getElementById('item_'+x).value = input;
            this.updateCartQuantity (input, x, '+', y);
        },

        setUnit(x,y){
            let input = Number(document.getElementById('item_'+x).value);
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '', y);
        },

        decrement(x,y){
            let input = Number(document.getElementById('item_'+x).value) - 1;
            input > 0 ? document.getElementById('item_'+x).value = input : this.removeFromCart(x);            
            this.updateCartQuantity (input, x, '-', y);
        },

        updateCartQuantity (value, product_id, action, pack) {
            let cart_array = this.$store.getters.cartdata;
            cart_array.forEach(i => {
                if (product_id == i.itemID) {
                    if (action == '+') {
                        i.qty = parseInt(i.qty) + 1;
                        i.unit = (parseInt(i.qty) + 1)/pack;
                    }
                    else if (action == '-') {
                        i.qty = parseInt(i.qty) - 1;
                        i.unit = (parseInt(i.qty) - 1)/pack;
                    }
                    else {
                        i.qty = value;
                        i.unit = value/pack;
                    }
                }
            })  

            this.$store.commit("updateCartdata", cart_array);
        },        

        unitCount(x){
            return this.cartarray.filter( el => el.itemID == x )[0].qty;
        },

        searchProduct(keyword){
            let subacct_code  = this.sub_acct.split('-')[1];
            let business_rules = subacct_code.charAt(0)
            if(business_rules !== 'B'){
                this.filterProduct('all');
            }


            let key = 'itemID';
            let product_list = this.all_product_list;
            
            let arrayUniqueByKey = [...new Map(product_list.map(item => [item[key], item])).values()];

            let product_group = arrayUniqueByKey;
            

            this.product_list = product_group.filter((data) =>data.itemID.includes(keyword) || data.productname.toLowerCase().includes(keyword.toLowerCase()))
        },

        unitFormatter(x){
            // 
            let lastChar = x.substr(x.length - 1)

            if(x == 'gal'){
                return 'Gallon';
            }
            else{
                if(lastChar == 's'){
                    return x.slice(0, -1);
                }
                else{
                    return x;
                }
            }
            
        },

        saveCart(){
            let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct, "cart":JSON.stringify(this.$store.getters.cartdata)});

            axios({
                method: "post",
                url: this.$hostname+"/savecart",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    console.log(res.data.status);
                }
            })

        },

        fetchCart(){
            let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
            axios({
                method: "get",
                url: this.$hostname+"/getcart",
                headers: { "Content-Type": "application/json", "Authorization" : `Bearer ${this.$store.getters.headerstoken}`},
                params:{'token':data},
            })
            .then(res => {
                if(res.data.status == true){
                    let cart = [];

                    let data = res.data.data;


                    let lcart = JSON.parse(data.cart_items);
                    

                    if(lcart !== null){
                        if(lcart.length > 0){
                            for(let i = 0; i < lcart.length; i++){
                                cart.push(lcart[i].itemID);
                            }
                        }

                        console.log(cart);
                        
                        this.$store.commit("updateCart", cart);
                        this.$store.commit("updateCartdata", lcart);
                    }
                }
            })
        },
    },
    
};
</script>

<style scoped>
.shadow-hover:hover {background: #FFFFFF; border: 1px solid rgba(0, 0, 0, 0.1); box-sizing: border-box; border-radius: 1px 1px 5px 5px;}
/* .shadow-hover:hover {background: #FFFFFF; border: 1px solid rgba(0, 0, 0, 0.1); box-sizing: border-box; border-radius: 1px 1px 5px 5px; box-shadow: inset 0px -9px 0px 0px rgb(0 110 64) !important;} */
.shadow-hover:hover{box-shadow: 0px 40px 40px rgba(0, 0, 0, 0.1);}
.add-cart-btn{ display: block;transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all; }
.shadow-hover:hover .add-cart-btn{display: block; transition: 1s all;  -webkit-transition: 1s all; -moz-transition: 1s all;  -o-transition: 1s all; -ms-transition: 1s all; }
.nav-link{font-weight: 600;color: #000 !important;padding: 10px 30px;}
.nav-link.active{ background: #006E40; color: #fefefe !important;}
.nav-item{margin-right: 10px;}
/* .nav-tabs {border-bottom: 1px solid #dee2e638;} */
.product-img{ height:120px}

.shadow-hover.col-lg-3{
    width: calc(25% - 30px);
    padding: 0;
    margin: 0 15px;

}

.min-order {
    position: absolute;
    top: 0;
    right: 0;
    background: green;
    color: white;
    font-size: 11px;
    padding: 5px;
    z-index: 200;
    border-radius: 0 0 0 5px;
}

.wrapper {
    cursor: help;
    -webkit-transform: translateZ(0);  /*webkit flicker fix */
    -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
    z-index: 100;
    position: absolute;
    width: 100%;
    top: 2px;
}

.wrapper .tooltip {
    background: #21824b;
    bottom: 100%;
    color: #fff;
    display: block;
    margin-bottom: 2px;
    opacity: 0;
    pointer-events: none;
    padding:10px 10px;
    position: absolute;
    width: calc(100% - 20px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
        transform: translateY(10px);
    -webkit-transition: all .25s ease-out;
    -moz-transition: all .25s ease-out;
    -ms-transition: all .25s ease-out;
    -o-transition: all .25s ease-out;
        transition: all .25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    text-align: center;
    font-size: 12px !important;
}

/* This bridges the gap so you can mouse into the tooltip without it disappearing */
.wrapper .tooltip:before {
    bottom: -20px;
    content: " ";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}

/* CSS Triangles - see Trevor's post */
/* .wrapper .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #21824b 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
} */

.wrapper .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-bottom: solid #21824b 10px !important;
    border-top: none;
    bottom: 34px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -50%;
    position: absolute;
    width: 0;
}

/* .wrapper:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
        transform: translateY(0px);
} */
.wrapper:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(68px);
}
/* IE can just show/hide with no transition */
.lte8 .wrapper .tooltip {
    display: none;  
}

.lte8 .wrapper:hover .tooltip {
    display: block;
}
.blink {
  animation: blink-animation 5s steps(15, start) infinite;
  -webkit-animation: blink-animation 5s steps(15, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>