<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><b>Sales Order</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Sales Order</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>
                

                <div class="row mb-5 mt-3">
                    <div class="col-lg-6">
                        <div class="card">
                            <div class="card-body">
                                <p>Customer’ s Name: <b>Mickel Majala</b></p>

                                <p><b>Depot:</b></p>
                                
                                <form  class="login-form" autocomplete="off" method="post">                                            
                                    <div class="form-group">
                                        <!-- <label>Phone Number</label> -->
                                        <b>Ship to:</b>
                                        <select required class="form-control select nm">
                                            <option>Lagos</option>

                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <b>Contact details:</b>
                                        <textarea type="email" class="form-control textarea nm" placeholder="Type here" required >No 2, Idiagunse street, Ogudugra, melancholy street, Aba north centre, Abuja.
+234-709-8789-099, +234-709-8789-099.</textarea>
                                    </div>

                                    <div :class="msg_status" v-html="msg_content"></div>

                                    <button type="submit" class="btn form-control login-btn">ADD ADDRESS</button>

                                </form>
                            </div>

                            <div class="col-lg-3 text-center offset-lg-4 mt-3">
                            </div>                   
                        </div> 
                    </div>
                    <div class="col-lg-6">
                        
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between">
                                    <p>Confirmation date:</p>
                                    <b>5/12/2021</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Gatepass no:</p>
                                    <b>GN01973322</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Ref no:</p>
                                    <b>SO2363493</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Expiring date:</p>
                                    <b>8/19/2021</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Your Reference:</p>
                                    <b>------</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Created date:</p>
                                    <b>5/21/2021</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Created time:</p>
                                    <b>1:28:42 PM</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>INTO PO:</p>
                                    <b>-----</b>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <p>Delivery time:</p>
                                    <b>EXW</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3 mb-3">
                    <div class="col-lg-12">
                        <table class="table table-bordered table-striped table-hover">
                        <thead>
                            <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Quantity (in words)</th>
                            <th scope="col">Weight</th>
                            <th scope="col">Freight</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Product Quantity (in words) Weight Freight
                                FG00019 :: GP Semovita 5 x 2kg - New Pack!
                                Lot ID: LOT6577834
                                </th>
                                <td><b>100,000</b> <br> One Hundred Thousand naira</td>

                                <td><b>1,000.00</b></td>
                                <td><b>1,000.00</b></td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                </div>

                <div class="row mt-4 mb-4">
                    <form class="" autocomplete="off" method="post">
                        <div class="row font-12">
                            <div class="col-lg-4 mb-3">
                                <div class="form-group">
                                    <b>Transporter</b>
                                    <input type="text" class="form-control font-12 bolder" placeholder="Transporter" required>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="form-group">
                                    <b>Vehicle No:</b>
                                    <input type="text" class="form-control font-12 bolder" placeholder="Vehicle No:" required>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="form-group">
                                    <b>Checked by:</b>
                                    <input type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  minlength="11" maxlength="11" class="form-control font-12 bolder" placeholder="Checked by:" required>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="form-group">
                                    <b>Loading bay:</b>
                                    <input type="email" class="form-control font-12 bolder" placeholder="Loading bay:"  required>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="form-group">
                                    <b>Date Loaded:</b>
                                    <input type="email" class="form-control font-12 bolder" placeholder="Date Loaded:"   required>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="form-group">
                                    <b>Time Loaded:</b>
                                    <input type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"  minlength="11" maxlength="11" class="form-control font-12 bolder" placeholder="Time Loaded:"  required>
                                </div>
                            </div>


                            <div class="col-lg-12 mb-5"></div>
                            
                            <div class="col-lg-4 mb-3">
                                <div class="form-group text-center">
                                    <input type="email" class="form-control font-12 bolder" placeholder=""  required>
                                    <b>Authorised by Name  / Signature</b>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="form-group text-center">
                                    <input type="email" class="form-control font-12 bolder" placeholder=""  required>
                                    <b>Driver</b>
                                </div>
                            </div>
                            <div class="col-lg-4 mb-3">
                                <div class="form-group text-center">
                                    <input type="email" class="form-control font-12 bolder" placeholder=""  required>
                                    <b>Security Manager</b>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

                <div class="row mt-5">
                    <div class="col-lg-6">
                        <p class="p-3 border shadow">PLEASE NOTE THAT GOODS ARE CARRIED AT TRANSPORTER'S RISK  & ORDER VALID FOR 90 DAYS FROM DATE OF DOCUMENT.</p>
                    </div>

                    <div class="mt-5 mb-5 col-lg-4 offset-lg-4">
                        <button class="btn bg-secondary shadow border btn-large form-control p-2 rounded-pill"><b>Print Sales Order</b></button>
                    </div>
                </div>


                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';



export default {
    name: "SalesOrder",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
           page_active: "orders"
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Dashboard";
            },
        },
    },

    mounted () {    
        this.inactivityTime();     
        this.renderChart(this.chartdata, this.options)
    },

    methods: {

    },

    created () {
    }
};
</script>

<style scoped>
tr, th, td{
    border: 1px solid #7d7777;
}
</style>

