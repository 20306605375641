<template>
    <div class="dashboard">

        <SideNav v-bind:page_active="page_active" />

        <div class="main-section">

            <TopNav />

            <div class="content-body">
                <div class="row breadcrumb-area">
                    <div class="col-lg-6">
                        <h6><a href="/wallet" title="Go back to Accounts"><i class="fa fa-arrow-left text-dark"></i> &nbsp;&nbsp;&nbsp;&nbsp;Go Back </a><br><br> <b>Transfer Fund</b></h6>
                    </div>

                    <div class="col-lg-6">
                        <nav aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                                <li class="breadcrumb-item"><a href="/wallet">Wallet</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Transfer Fund</li>
                            </ol>
                        </nav>                   
                    </div>
                </div>


                <div class="row mt-3 mb-5">
                    <div class="col-lg-4">

                        <form  class="login-form card p-4" autocomplete="off" v-on:submit.prevent="handleTransfer" method="post">
                            <div class="form-group">
                                <b>Transfer From:</b>
                                <select type="text" autofocus class="form-control select" v-model="accounfundTransfer.sendersubaccountid" required>
                                    <option selected value="">Select Account</option>
                                    <option :class="accounfundTransfer.receiversubaccountid == x.customeraccount ? 'd-none' : ''" v-for="x in subprofiles" :key="x.customeraccount" :value="x.customeraccount" :style="x.customergroup == 'TradeCash' ? '':'display:none'">{{x.customeraccount}} : &#x20A6;{{thDFormat(Number(x.creditremaining))}}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <b>Transfer To:</b>
                                <select type="text" autofocus class="form-control select" v-model="accounfundTransfer.receiversubaccountid" required>
                                    <option selected value="">Select Account</option>
                                    <option :class="accounfundTransfer.sendersubaccountid == x.customeraccount ? 'd-none' : ''" v-for="x in subprofiles" :key="x.customeraccount" :value="x.customeraccount" :style="x.customergroup == 'TradeCash' ? '':'display:none'"> {{x.customeraccount}} : &#x20A6;{{thDFormat(Number(x.creditremaining))}}</option>
                                </select>
                            </div>
                            
                            <div class="form-group">
                                <b>Amount:</b>
                                <input required type="text" autocomplete="off" autofocus class="form-control" placeholder="In Naira" id="amount" v-on:keyup="thFormat" v-model="amount"  />
                            </div>
                            <div class="form-group">
                                <b>Pin:</b>
                                <input required type="password" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" minlength="4" maxlength="4" autofocus class="form-control" placeholder="your Pin"  v-model="accounfundTransfer.secretpin"  />
                            </div>
                            <div class="form-group">
                                <b>Description:</b>
                                <textarea style="border: 1px solid #000;" class="form-control" placeholder="Description"  v-model="accounfundTransfer.description" ></textarea>
                            </div>

                            <!-- <div :class="msg_status" v-html="msg_content"></div> -->

                            <button type="submit" class="btn form-control login-btn" :disabled='isDisabled'>{{processBtn}}</button>
                        </form>

                    </div>

                </div>


                <Copyright />
            </div>
        </div>
    </div>
</template>




<script>
import SideNav from '@/components/SideNav.vue';
import Copyright from '@/components/Copyright.vue';
import TopNav from '@/components/TopNav.vue';
import axios from 'axios';


export default {
    name: "TransferFund",
    components: {
        SideNav, TopNav, Copyright
    },
    
    data() {
        return{
            page_active:"wallet",
            subprofiles:[],

            accounfundTransfer:{
                subaccountid: "",
                receiversubaccountid: "",
                sendersubaccountid:"",
                amount: 0,
                transactionreference: "09569065",
                description: "",
                secretpin:''
            },
            amount:0,

            isDisabled: false,
            processBtn: 'TRANSFER',
            msg_status: '',
            msg_content: '',
            hasTransfer:false,
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = "FMN | Fund Transfer";
            },
        },
    },   
    
    mounted() {
        this.inactivityTime(); 
        if (this.$store.getters.vendor_no) {
            this.phone      = this.$store.getters.phone; 
            this.vendor_no  = this.$store.getters.vendor_no;    
            this.sub_acct   = this.$store.getters.sub_acct;            
        }

        this.subprofiles =  this.$store.getters.subaccounts;
        // this.fetchAccounts();    
    },

    methods: {
        async handleTransfer () {
            try {
                
                let frombal     = this.$store.getters.subaccounts.filter( el => el.customeraccount == this.accounfundTransfer.sendersubaccountid)[0].creditremaining;



                this.msg_status     = '';
                this.msg_content    = '';
           
                this.accounfundTransfer.subaccountid   = this.$store.getters.vendor_no;
                this.accounfundTransfer.amount         = parseFloat(this.amount.replace(/,/g, ''))


                if(frombal >= this.accounfundTransfer.amount){
                    
                    this.processBtn     = 'Loading...';                     
                    this.isDisabled = true;


                    let data = this.loadNew(this.accounfundTransfer);
                    await axios({
                        method: "post",
                        url: this.$hostname+"/postbalancesbetweensubaccounts",
                        headers: { "Content-Type": "application/json" ,
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}` },
                        data: {'token':data},
                    })

                    .then(res => {
                        let response = res.data;

                        if(response.status == true){
                            
                            this.accounfundTransfer = {
                                subaccountid: "",
                                receiversubaccountid: "",
                                sendersubaccountid:"",
                                amount: 0,
                                transactionreference: "09569065",
                                description: "",
                                secretpin:''
                            }
                            this.amount = 0;

                            this.fetchAccounts();

                            this.msg_status     = 'alert alert-success mt-4';
                            this.msg_content    = response.message;                     
                            this.isDisabled     = false;
                            this.processBtn     = 'TRANSFER'; 
                            this.hasTransfer    = true; 
                            
                        }
                        else{
                            this.msg_status     = 'alert alert-danger mt-4';
                            this.msg_content    = response.message;                       
                            this.isDisabled     = false;
                            this.processBtn     = 'TRANSFER';
                            this.hasTransfer    = false; 

                            if(response.reset){
                                this.$swal.fire(
                                    'Alert',
                                    this.msg_content,
                                    'error'
                                ).then(okay => {
                                    if (okay) {
                                        window.location.href = "/reset-pin";
                                    }
                                })
                            }
                            else{
                                this.$swal.fire(
                                    'Alert',
                                    this.msg_content,
                                    'error'
                                )
                            }
                        }  
                    })

                    .catch(error => {
                        console.log(error)
                        this.is401(error.response.status);
                    })  

                    .finally(e => {
                    this.isDisabled     = false;
                    this.processBtn     = 'TRANSFER';            
                    console.log(e);
                    })
                }
                else{                    
                    this.$swal.fire(
                        'Alert',
                        'Insufficient Fund!',
                        'error'
                    )
                }
           
           }
            catch (err) {           
                console.log(err);
            }            
        },

        async fetchAccounts () {
            var loading_html =
                '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100%;" src="https://www.c-sgroup.com/images/loading-icon-red.gif" /></div>';
                this.$swal.fire({
                title: "",
                html: loading_html,
                showConfirmButton: false,
                showCancelButton: false
            });

            try {
                
                let data = this.loadNew({"subaccountID":this.$store.getters.sub_acct});
                await axios({
                    method: "get",
                    url: this.$hostname+"/getsubaccountID",
                    headers: { "Content-Type": "application/json",
                            "Authorization" : `Bearer ${this.$store.getters.headerstoken}`  },
                    params:{'token':data},
                })

                .then(res => {
                    if(res.data.status == true){
                        this.$store.commit("set_subaccounts", res.data.data);

                        let sub_acct   = this.$store.getters.sub_acct
                        let newbal     = res.data.data.filter( el => el.customeraccount == sub_acct );
                        this.$store.commit("set_sub_acct_bal", newbal[0].creditremaining);
                        
                        this.subprofiles = this.$store.getters.subaccounts;

                        if(this.hasTransfer){
                             this.$swal.fire(
                                'Success Alert',
                                this.msg_content,
                                'success'
                            )
                        }
                        else{
                             this.$swal.fire(
                                'Alert',
                                this.msg_content,
                                'error'
                            )
                        }
                    }
                })
            }
            catch (err) {           
                console.log(err);
            }            
        },

        thFormat(){
            let num = parseFloat(this.amount.replace(/,/g, '')) //remove any currency format;
            isNaN(num) ?  this.amount = '' :  this.amount = Number(num).toLocaleString();
        },
    },

    beforeMount(){

    },

    created () {
    }
};
</script>

<style scoped>
.login-form input.form-control, .select {
    margin: 0 0 24px 0px;
}
.form-group b{
    font-size: 12px;
}
</style>